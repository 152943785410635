import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Gallery from "@browniebroke/gatsby-image-gallery"

// if there are multiple things being exported
// from utils (which there are) then we need
// to use the curry braces around the component
import { AboutHeader, BannerCenter } from "../utils"
import img from "../images/bcg/eat-organic.jpg"
import imgKeyLime from "../images/bcg/key-lime-pie.png"
import imgPasta from "../images/bcg/chick-de-bianco.png"



const AboutPage = ({ data }) => {
  const images = data.images.edges.map(({ node }) => ({
    ...node.childImageSharp,
    // Use original name as caption.
    // The `originalName` is queried inside the `thumb` field,
    // but the `Gallery` component expects `caption` at the top level.
    caption: node.childImageSharp.thumb.originalName,
  }))
  return(
    <Layout>
      <SEO title="About" keywords={[`About Us`, `Chef Kevin Faini`, `Local organic farm to table cuisine`]} />
      <AboutHeader img={img}>
        <BannerCenter
          title="Meet the Chef"
          subtitle="Chef Kevin Faini’s fondness for humble, Southern ingredients comes directly from his roots in nearby Rutherford County."
        >
          <hr
            style={{ background: `tan`, height: `0.1em`, margin: `1.5rem 0` }}
          />
          <div id="about">
            <div className="row justify-content-center align-content-center py-3">
              <div className="bc-column col-sm-11 col-md-11 col-lg-11 align-self-center order-1 mb-5 mb-md-3">
                <p>
                  Surrounded by a diverse family of Southern and Italian heritage
                  with a passion for fresh food, Foragers Canteen's Executive Chef,
                  Kevin Faini, was long ago introduced to the idea mixing and
                  matching world cuisines in the quest for innovative new dishes.
              </p>
              </div>
            </div>

            <div className="row justify-content-center align-content-start py-3">
              <div className="bc-column col-sm-2 col-md-3 col-lg-2 align-self-start order-1 mx-5 px-5 mx-sm-0 px-sm-0">
                <img
                  src={imgKeyLime}
                  alt="Key Lime Cocktail"
                  style={{ width: "100%", height: "auto" }}
                ></img>
              </div>

              <div className="bc-column col-sm-9 col-md-9 col-lg-9 align-self-center order-2 mx-2 mx-sm-0 my-3">
                <p>
                  Chef Faini was most influenced by his grandmothers, who grew,
                  canned, and preserved food with love and care to feed their
                  families fresh, handmade food every day. From an early age, he
                  developed a deep connection to food, the land, and farming. Chef
                  Faini turned that love of food into a 16 year career, working in
                  and leading high-end kitchens in Lake Lure, Cashiers, and
                  Highlands.
              </p>
              </div>
            </div>

            <div className="row justify-content-center align-content-start py-3">
              <div className="bc-column col-sm-11 col-md-8 col-lg-8 align-self-center order-1">
                <p>
                  Today, as Corporate Chef and Food and Beverage Director of
                  Foragers Canteen in eclectic Dillsboro,NC, Chef Faini celebrates
                  lessons learned from his family and career, combining local,
                  seasonally-fresh food and gracious service to create a memorable
                  dining experience.
              </p>
              </div>

              <div className="bc-column col-sm-4 col-md-3 col-lg-3 align-self-center order-2 mx-5 px-5 mx-sm-0 px-sm-0 py-3 pt-5 pt-sm-5">
                <img
                  src={imgPasta}
                  alt="Chick-de-bianco Pasta"
                  style={{ width: "100%", height: "auto" }}
                ></img>
              </div>
            </div>
          </div>
        </BannerCenter>
      </AboutHeader>
      <div id="gallery">
        <Gallery images={images} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ImagesForGallery {
    images: allFile(
      filter: { relativeDirectory: { eq: "gallery" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
              originalName
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default AboutPage
